<template>
  <div>
    <b-row>
      <b-col
        sm="8"
        md="8"
      >
        <i2-form-input
          v-model="address.postcode"
          rules="required"
          label="CEP"
          mask="#####-###"
          placeholder="Ex: 00000-000"
          name="cep"
          :readonly="isBlock"
          :disabled="isBlock"
        />
      </b-col>
      <b-col
        sm="4"
        md="4"
        class="align-items-center"
      >
        <b-button
          variant="light"
          class="btn-icon mr-1"
          @click="clearForm"
        >
          <feather-icon icon="DeleteIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="address.address">
      <b-col
        sm="6"
        md="6"
      >
        <i2-form-input
          v-model="address.address"
          rules="required"
          label="Logradouro"
          name="logradouro"
        />
      </b-col>
      <b-col
        sm="6"
        md="6"
      >
        <i2-form-input
          v-model="address.neighborhood"
          rules="required"
          label="Bairro"
          name="Bairro"
        />
      </b-col>
      <b-col
        sm="6"
        md="6"
      >
        <i2-form-input
          v-model="address.city"
          rules="required"
          label="Cidade"
          name="localidade"
        />
      </b-col>
      <b-col
        sm="6"
        md="6"
      >
        <i2-form-input
          v-model="address.state"
          rules="required"
          mask="AA"
          label="Estado"
          name="uf"
        />
      </b-col>
      <b-col
        sm="6"
        md="4"
      >
        <i2-form-input
          v-model="address.number"
          label="Número"
          name="Número"
        />
      </b-col>
      <b-col
        sm="6"
        md="8"
      >
        <i2-form-input
          v-model="address.complement"
          label="Complemento"
          name="complemento"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'

export default {
  name: 'I2FormAddressInput',
  components: { I2FormInput },
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBlock: false,
      address: {
        postcode: '',
        address: '',
        complement: '',
        neighborhood: '',
        city: '',
        city_code: '',
        state: '',
        number: '',
      },
    }
  },
  watch: {
    'address.postcode': function (value) {
      this.getInfoCep(value)
    },
    address() {
      this.$emit('input', this.address)
    },
    value(value) {
      if (value) {
        this.address = value
      }
    },
  },
  mounted() {
    if (this.value) {
      this.address = { ...this.value }
    }
  },
  methods: {
    clearForm() {
      this.address = {
        postcode: '',
        address: '',
        complement: '',
        neighborhood: '',
        city: '',
        city_code: '',
        state: '',
        number: '',
      }
    },
    async getInfoCep() {
      if (this.isBlock) {
        return
      }

      const { postcode } = this.address
      if (postcode.length === 9) {
        const searchCep = postcode.replace('-', '')
        fetch(`https://viacep.com.br/ws/${searchCep}/json/`)
          .then(response => response.json())
          .then(data => {
            this.address = {
              postcode: this.address.postcode,
              address: data.logradouro ? data.logradouro : this.address.address,
              city: data.localidade ? data.localidade : this.address.city,
              neighborhood: data.bairro ? data.bairro : this.address.neighborhood,
              state: data.uf ? data.uf : this.address.state,
              city_code: data.ibge,
              number: data.numero ? data.numero : this.address.number,
              complement: this.address.complement,
            }
          })
          .catch(err => {
            console.log('Cep Nao encontrado', err)
          })
      }
    },
  },
}
</script>

<style scoped>
</style>
