import { mapGetters } from 'vuex'

const windowWidthMixin = {
  computed: {
    ...mapGetters('app', ['currentBreakPoint']),
    windowWitdh() {
      switch (this.currentBreakPoint) {
        case 'lg':
          return '50vw'
        case 'xl':
          return '50vw'
        case 'sm':
          return '100vw'
        case 'xs':
          return '100vw'
        default:
          return '50vw'
      }
    },
  },
  methods: {
    banana() {
      return 'banana'
    },
  },
}

export default windowWidthMixin
