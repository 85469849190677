<template>
  <div>
    <i2-form-vue-select
      v-model="selectedValue"
      v-bind="$attrs"
      :options="estados"
      :name="name"
      :label="label"
      :rules="rules"
    />
  </div>
</template>

<script>

export default {
  name: 'StatesBr',
  props: {
    name: {
      default: '',
      type: String,
      required: true,
    },
    label: {
      default: '',
      type: String,
      required: true,
    },
    rules: {
      default: '',
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
  },
  data() {
    return {
      estados: [
        { sigla: 'AC', text: 'AC' },
        { sigla: 'AL', text: 'AL' },
        { sigla: 'AP', text: 'AP' },
        { sigla: 'AM', text: 'AM' },
        { sigla: 'BA', text: 'BA' },
        { sigla: 'CE', text: 'CE' },
        { sigla: 'DF', text: 'DF' },
        { sigla: 'ES', text: 'ES' },
        { sigla: 'GO', text: 'GO' },
        { sigla: 'MA', text: 'MA' },
        { sigla: 'MT', text: 'MT' },
        { sigla: 'MS', text: 'MS' },
        { sigla: 'MG', text: 'MG' },
        { sigla: 'PA', text: 'PA' },
        { sigla: 'PB', text: 'PB' },
        { sigla: 'PR', text: 'PR' },
        { sigla: 'PE', text: 'PE' },
        { sigla: 'PI', text: 'PI' },
        { sigla: 'RJ', text: 'RJ' },
        { sigla: 'RN', text: 'RN' },
        { sigla: 'RS', text: 'RS' },
        { sigla: 'RO', text: 'RO' },
        { sigla: 'RR', text: 'RR' },
        { sigla: 'SC', text: 'SC' },
        { sigla: 'SP', text: 'SP' },
        { sigla: 'SE', text: 'SE' },
        { sigla: 'TO', text: 'TO' },
      ],
      selectedValue: '',
    }
  },
  watch: {
    selectedValue(val) {
      if (val && typeof val === 'object' && val.sigla) {
        this.$emit('onSelect', val.sigla);
        this.$emit('input', val.sigla);
      } else if (typeof val === 'string') {
        this.$emit('onSelect', val);
        this.$emit('input', val);
      } else {
        this.$emit('onSelect', null);
        this.$emit('input', null);
      }
    },
    value(val) {
      if (val) {
        this.selectedValue = typeof val === 'string' ? val : val.sigla;
      } else {
        this.selectedValue = null;
      }
    }
  }
}
</script>
